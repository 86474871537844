@import "vars";
@import url('https://fonts.googleapis.com/css2?family=Roboto&display=swap');

$border-radius: 60px;
$border-width: 63%;
$border-weight: 4px;
$border-color: #98C1FE;

.vacancies {
  position: relative;
  .done-img{
    width: 210px;
    position: absolute;
    top: 50px;
    left: -93px;
  }
  padding-top: 153px;
  padding-bottom: 153px;

  &__header{
    width: fit-content;
    padding: 46px 78px;
    position: relative;
    height: 100%;
    border: $border-color $border-weight solid;
    border-bottom: none;
    border-radius: $border-radius $border-radius 0 0;
    backdrop-filter: blur(50px);
  }

  &__content{
    display: flex;
    flex-direction: column;
    gap: 20px;

    width: 100%;
    border: $border-weight solid $border-color;
    border-radius: 0 $border-radius $border-radius $border-radius;
    position: relative;
    padding: 15px 26px 46px;
    backdrop-filter: blur(50px);
  }

  @media screen and (max-width: 685px){
    padding-top: 77px;
    padding-bottom: 75px;
    .done-img{
      width: 76px;
      top: 33px;
      left: 0;
    }

    $border-radius: 30px;
    &__header{

      width: fit-content;
      padding: 20px 28px;
      border: $border-color 2px solid;
      border-bottom: none;
      border-radius: $border-radius $border-radius 0 0;
    }

    &__content{
      border: 2px solid $border-color;
      border-radius: 0 $border-radius $border-radius $border-radius;
      position: relative;
      padding: 15px 26px 46px;
      backdrop-filter: blur(50px);
    }
  }
}

.vacancy{
  width: 100%;
  padding: 25px 55px;
  border-radius: 25px;

  .back{
    margin-left: auto;
    margin-bottom: 45px;
    color: $primary-color;
    font-size: 18px;
    font-weight: 400;
    cursor: pointer;
    width: fit-content;

    &:hover{
      color: $primary-active-color;
    }
  }

  &__header{
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .vacancy__footer__mobile{
    display: none;
    justify-content: space-between;
    align-items: center;
    gap: 18px;
    margin-top: 21px;
    .back, .respond{
      display: initial;
      margin: 0;
    }
    .respond{
      font-size: 14px;
      width: 195px;
    }
  }

  &__description{
    font-size: 18px;
    font-weight: 400;
    white-space: pre-line;
    margin-top: 41px;
    font-family: 'Roboto', sans-serif;
    ul{
      list-style-type: initial;
      margin-bottom: 1em;
    }
  }
  &__heading{
    font-size: 1.3em;
    font-weight: 400;
  }
  &__main-heading{
    font-size: 1.5em;
    font-weight: 400;
    margin-bottom: 1em;
  }

  &__active{
    background: rgba(255, 255, 255, 0);
    transition: background-color 0.2s linear;
    cursor: pointer;
    &:hover{
      .arrow-link{
        background: $primary-active-color;
      }
    }
  }

  &__active.vacancy__frame-hover{
    box-shadow: 0 4px 50px 0 rgba(0, 94, 255, 0.10);
    background: rgba(255, 255, 255, 1);
  }

  &__title{
    font-size: 36px;
    font-weight: 400;
    max-width: 43%;
  }

  .respond{
    padding: 20px 24px;
    width: 217px;
    border-radius: 20px;
    background: $primary-color;
    cursor: pointer;
    color: #FFF;
    font-size: 20px;
    font-weight: 700;
    border: none;
    outline: none;
    &:hover{
      background: $primary-active-color;
    }
  }

  &__status{
    font-size: 32px;
    font-weight: 400;
    display: flex;
    align-items: center;
    span{
      margin-right: 20px;
    }
    &__active{
      color: #90CE5E;
    }
    .arrow-link{
      cursor: pointer;
    }
  }
  &__fire{
    width: 40px;
    height: 40px;
    margin-right: 5px;
  }

  @media screen and (max-width: 685px){
    padding: 9px 18px;
    border-radius: 15px;

    .vacancy__footer__mobile{
      display: flex;
    }

    .back, .respond{
      display: none;
    }

    &__title{
      font-size: 18px;
    }
    &__status{
      font-size: 16px;
      span {
        display: none;
      }

      .arrow-link{
        transform: scale(.5);
      }
    }
    &__fire{
      width: 36px;
      height: 36px;
    }
  }
}
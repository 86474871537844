@import "vars";

.footer{
  background: $primary-gradient-color;
  color: $primary-light-color;
  position: relative;
  z-index: -2;

  &__contacts{
    display: flex;
    align-items: center;
  }
  &__content{
    font-weight: 500;
    padding-top: 71px;
    padding-bottom: 108px;
  }

  &__phone-and-email{
    font-size: 36px;
    margin-bottom: 22px;
  }
  &__address{
    font-size: 20px;
  }

  h3{
    font-size: 58px;
    font-weight: 400;
    line-height: 110%;
    margin-bottom: 76px;
  }

  //#targetPosition{
  //  width: 457px;
  //  height: 320px;
  //  top: 62px;
  //  right: 0;
  //  display: block;
  //  //background: red;
  //}

  .copyright{
    padding: 16px;
    font-size: 14px;
    text-align: center;
    border-top: 1px solid rgba(255, 255, 255, 0.20);
    margin: 0 40px;
  }

  @media screen and (max-width: 940px){
    &__contacts{
      flex-direction: column-reverse;
      align-items: center;
    }
    img{
      margin-top: -160px;
    }
    &__content{
      text-align: center;
    }
  }

  @media screen and (max-width: 685px){
    &__content{
      font-weight: 500;
      padding-top: 35px;
      padding-bottom: 108px;
    }

    &__phone-and-email, &__address{
      font-size: 18px;
    }

    &__phone-and-email {
      margin: 51px 0;
    }
    &__content{
      padding-bottom: 11px;
    }
    h3{
      font-size: 25px;
      margin: 0;
    }
    img{
      width: 298px;
      height: 208px;
      margin-top: -104px;
    }
  }
}
@import "vars";


*{
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

html{
  scroll-behavior: smooth;
}

body{
  background: $primary-light-color;
}
main{
  overflow: hidden;
}

a{
  text-decoration: none;
  color: inherit;
}

ul{
  list-style-type: none;
}

.container{
  max-width: 1120px;
  margin: auto;
  width: 100%;
  padding: 24px;
}

h2{
  font-size: 50px;
  font-weight: 400;
  font-family: 'Unbounded', sans-serif;
  span{
    color: $primary-color;
  }

  @media screen and (max-width: 685px){
    font-size: 25px;
  }
}

.target-position{
  width: 534px;
  height: 374px;
  &__footer{
    width: 400px;
  }
  @media screen and (max-width: 685px){
    width: 334px;
    height: 234px;
    &__footer{
      width: 298px;
      height: 208px;
    }
  }
}

.scene-container{
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  max-height: 740px;
  z-index: -1;

}

@media screen and (min-width: 685px) and (max-width: 1228px){

}

@import "header";
@import "ui";
@import "vacancies";
@import "who-we-are";
@import "why-we-are";
@import "send-cv";
@import "comments";
@import "footer";


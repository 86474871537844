@import "vars";

.comments{
  padding-bottom: 202px;
  h2{
    text-align: center;
  }

  @media screen and (max-width: 685px){
    padding-top: 0;
    padding-bottom: 229px;
  }
}

.slider-wrapper{
  position: relative;
  border-radius: 50px;
  border: 4px solid #98C1FE;
  background: rgba(255, 255, 255, 0.10);
  backdrop-filter: blur(50px);
  margin-top: 77px;
  @media screen and (max-width: 685px) {
    border-radius: 20px;
  }
}

.slider__navigation{
  position: absolute;
  display: flex;
  gap: 19px;
  bottom: 60px;
  right: 70px;
  @media screen and (max-width: 685px){
    transform: scale(.5) translate(-50%, -50%);
    bottom: 0;
    right: initial;
    left: 35px;
  }
}

.comment{
  width: 100%;
  height: 507px;
  padding: 60px 70px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  p{
    font-size: 36px;
    font-weight: 400;
  }

  &__footer{
    display: flex;
    justify-content: space-between;
  }
  &__author{
    display: flex;
    width: fit-content;
    justify-content: center;
    align-items: center;
    gap: 24px;
    img{
      width: 64px;
      height: 64px;
      border-radius: 20px;
    }
  }
  &__author-name{
    font-size: 24px;
    font-weight: 400;
  }
  &__author-position{
    font-size: 20px;
    font-weight: 600;
  }

  &__navigation{
    height: 64px;
    width: calc(64px * 2 + 19px);
  }

  &__button{
    width: 64px;
    height: 64px;
    border-radius: 20px;
    border: 2px solid #476FFF;
    display: flex;
    justify-content: center;
    align-items: center;
    background: $primary-light-color;
    cursor: pointer;
    transition: background-color 0.2s ease-in-out;
    
    &:hover{
      background: $primary-active-color;
      svg path {
        fill: $primary-light-color;
      }
    }
    &:last-child{
      transform: scale(-1);
    }
  }

  @media screen and (max-width: 685px){
    padding: 35px 35px 0;
    height: auto;

    p{
      font-size: 14px;
    }

    &__author{
      gap: 10px;
      img{
        width: 32px;
        height: 32px;
      }
    }
    &__author-name, &__author-position{
      font-size: 14px;
    }
    &__footer{
      flex-direction: column;
      gap: 31px;
      margin-top: 31px;
    }
    &__navigation{
      transform: scale(.5) translate(-50%, -50%);
    }

  }
}
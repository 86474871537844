@import "vars";

.send-cv{
  h2{
    text-align: center;
  }
  padding-bottom: 200px;
  position: relative;
}

.form-container{
  padding: 70px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 50px;
  margin: 50px auto 0;
  width: fit-content;
  border-radius: 50px;
  background: linear-gradient(110deg, rgba(0, 82, 205, 0.30) 0%, rgba(0, 82, 205, 0.30) 100%);
  backdrop-filter: blur(50px);

  &__sent{
    width: 100%;
  }
  &__after-send{
    width: 100%;
    height: 100%;
    background: $primary-light-color;
    border-radius: 37px;
    padding: 146px 0;
    h2{
      width: 457px;
      margin: auto;
    }
    p{
      font-size: 24px;
      width: 715px;
      margin: 30px auto 0;
      text-align: center;
    }
  }
}

.form{
  display: flex;
  flex-direction: column;
  gap: 30px;
  width: min-content;

  input[type="submit"]{
    padding: 20px;
    text-align: center;
    width: 100%;
    border-radius: 20px;
    background: $primary-color;
    font-size: 20px;
    font-weight: 700;
    color: $primary-light-color;
    border: none;
    outline: none;
    cursor: pointer;
    transition: background-color 0.2s ease-in-out;
    &:hover{
      background: $primary-active-color;
    }
    &:disabled{
      background: $secondary-active-color;
    }
  }

  &__row{
    display: flex;
    justify-content: space-between;
    gap: 10px;
  }
  &__file-info{
    color: $primary-light-color;
    font-size: 16px;
    font-weight: 400;
    max-width: 100%;
    position: relative;
  }
  &__file-error{
      position: absolute;
      top: -1.3em;
  }
  .file-name{
    overflow: hidden;
    max-width: 400px;
    white-space: nowrap;
    display: block;
  }
}

.tags{
  max-width: 510px;
  padding: 40px;
  border-radius: 50px;
  background: #FFF;
  backdrop-filter: blur(50px);

  &__title{
    font-size: 36px;
    font-weight: 700;
    line-height: 110%;
    height: 140px;
    background: var(--BLUE-GRAD, linear-gradient(134deg, #0039CC 0%, #005EFF 101.41%));
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
}

.tag-area{
  display: flex;
  gap: 10px;
  flex-wrap: wrap;

  .tag{
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    padding: 10px 20px;
    border-radius: 99px;
    border: 1px solid #000;
    background: #FFF;
  }
}

.cv-dude{
  position: absolute;
  &__big{
    width: 268px;
    right: 0;
    top: 70px;
    transform: translate(50%, 0);
    z-index: 1;
  }
  &__small{
    width: 215px;
    left: 0;
    bottom: 125px;
    transform: translate(-60%, 0) scaleX(-1);
    z-index: -2;
  }
}



@media screen and (max-width: 1070px) and (min-width: 685px){
  //.form-container{flex-direction: column;}
  .tags{
    padding: 40px 20px;
    border-radius: 20px;
    &__title{
      font-size: 20px;
      width: 217px;
      height: 77px;
    }
  }
  .form {
    //gap: 20px;
    &__file-info, input[type="text"], input[type="submit"] {
      font-size: 14px;
    }

    input[type="submit"]{
      padding: 14px;
      border-radius: 10px;
    }
  }
}

@media screen and (max-width: 685px){
  .form-container{flex-direction: column;}

  .send-cv{
    padding-bottom: 48px;
  }

  .cv-dude{
    display: none;
  }
  .form {
    gap: 20px;
    &__file-info, input[type="text"], input[type="submit"] {
      font-size: 14px;
    }

    input[type="submit"]{
      padding: 14px;
      border-radius: 10px;
    }
  }

  .tags{
    padding: 40px 20px;
    border-radius: 20px;
    &__title{
      font-size: 20px;
      width: 217px;
      height: 77px;
    }
  }

  .tag-area{
    .tag{
      font-size: 12px;
    }
  }

  .form-container {
    padding: 25px 21px;
    border-radius: 20px;
    gap: 25px;
    margin-top: 28px;

    &__after-send{
      border-radius: 20px;
      padding: 192px 0;
      h2{
        max-width: 350px;
        width: 100%;
        margin: auto;
        padding: 0 41px;
      }
      p{
        font-size: 16px;
        width: 100%;
        margin: 20px auto 0;
        text-align: center;
        padding: 0 40px;
      }
    }
  }
}

@media screen and (max-width: 490px){
  .form {
    &, input[type="text"] {
      width: 100%;
    }
  }
}
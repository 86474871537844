@import "vars";

.header{
  padding-top: 42px;
  position: relative;
  &__bg{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: radial-gradient(64.31% 75.9% at 50% 0%, rgba(0, 94, 255, 0.50) 0%, rgba(0, 94, 255, 0.00) 100%);
    z-index: -2;
  }

  &__content{
    display: flex;
    padding: 43px 80px;
    margin-top: 100px;
  }
  &__text{
    max-width: 500px;
    p{
      font-size: 18px;
      font-weight: 600;
      margin-top: 33px;
    }
  }

  &__swipe{
    text-align: center;
    margin-top: 40px;
    >*:last-child{
      display: none;
    }
  }
  .swipe-rect{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 30px;
    height: 38px;
    border-radius: 8px;
    border: 2px solid $primary-color;
    svg{
      transition: transform 0.3s ease-in-out;
    }
    &:hover{
      svg{
        transform: translateY(5px);
      }
    }
  }
  a{
    display: inline-block;
  }

  h1{
    width: 515px;
    font-size: 64px;
    font-weight: 600;
  }
}

.nav-bar{
  display: flex;
  justify-content: center;
  gap: 30px;
  position: relative;
  z-index: 2;
}

nav{
  width: 100%;
  max-width: 730px;
  display: flex;
  align-items: center;
  padding: 10px 10px 10px 20px;
  background: $primary-light-color;
  font-size: 14px;
  font-weight: 600;
  border-radius: 35px;
  box-shadow: 0 4px 25px 0 rgba(0, 0, 0, 0.10);

  ul{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-weight: 600;

    li:hover{
      color: $primary-color;
    }
  }
}

//@media screen and (min-width: 685px) and (max-width: 1228px){
//  .header__content img{
//    width: calc(534px / 1.3);
//    height: calc(374px / 1.3);
//  }
//}

@media screen and (max-width: 685px) {
  nav{
    display: none;
  }
  
  .header{
    padding-top: 28px;
    &__content{
      padding: 12px;
      flex-direction: column-reverse;
      align-items: center;
      margin-top: 10px;
      img{
        width: 334px;
        height: 234px;
        transform: translateY(0);
        margin: 0 0 41px 0;
      }
    }
    h1{
      width: 100%;
      font-size:  24px;
      text-align: center;
    }
    &__text{
      max-width: 100%;
      p{
      font-size: 14px;
      text-align: center;
      font-weight: 400;
      }
    }

    &__swipe{
      text-align: center;
      margin-top: 40px;
      >*:last-child{
        display: inline-block;
      }
      >*:first-child{
        display: none;
      }
    }
  }
}
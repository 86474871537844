@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;600;700&family=Unbounded:wght@400;600;700&display=swap');
@import "vars";

.logo {
  a {
    display: flex;
    gap: 10.29px;
    align-items: center;
  }

  svg {
    width: 52.708px;
    height: 58.959px;
  }

  &__text {
    display: inline-block;
    color: $primary-light-color;
    font-size: 16.508px;
    font-weight: 600;
  }
}

.montserrat{
  font-family: 'Montserrat', sans-serif;
}
.unbounded{
  font-family: 'Unbounded', sans-serif;
}

.button{
  border-radius: 20px;
  display: inline-block;
  padding: 20px;
  text-align: center;
  color: $primary-light-color;
  &__primary{
    background: $primary-color;
    &:hover{
      background: $primary-active-color;
    }
    &:disabled{
      background: $secondary-active-color;
    }
  }
  &__link{
    padding: 11px 20px;
    background: $primary-gradient-color;
  }
}

.arrow-link{
  background: $primary-color;
  padding: 20px;
  border-radius: 20px;
  width: 64px;
  height: 64px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: background-color 0.2s ease-in-out;

  svg{
    width: 35px;
    height: 35px;
  }
  //&:hover{
  //  background: $primary-active-color;
  //}
}

.input-field{
  position: relative;
  &__field{
    width: 400px;
    padding: 17px 20px;
    border-radius: 40px;
    background: $primary-light-color;
    font-size: 20px;
    font-weight: 400;
    border: none;
    outline: none;
    &__error{
      border: 2px solid #FF81AE;
    }
  }
  &__error{
    padding: .5em 20px 0;
    font-size: 14px;
    font-weight: 600;
    color: #FF81AE;
    position: absolute;
    bottom: -1.3em;
  }
}


.file-input {
  display: none;

  + .file-label {
    width: 64px;
    height: 64px;
    background-color: black;
    border-radius: 20px;
    cursor: pointer;

    display: flex;
    justify-content: center;
    align-items: center;
    transition: background-color 0.2s ease-in-out;

    &:hover{
      background-color: $secondary-active-color;
    }

    @media screen and (max-width: 685px){
      width: 45px;
      height: 45px;
      border-radius: 14px;

      svg{
        width: 25px;
        height: 25px;
      }
    }
  }
}
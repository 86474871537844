@import "vars";

@keyframes centerFromLeft {
  to {
    left: 50%;
    transform: translateX(-50%);
  }
}
@keyframes centerFromRight {
  to {
    right: 50%;
    transform: translateX(50%);
  }
}

.who-we-are{
  position: relative;
  max-width: 100vw;
  >img{
    position: absolute;
    width: 726px;
    z-index: -2;
    top: 51%;
    left: 50%;
    transform: translate(-50%, -24%);
  }

  &__wrapper{
    margin: auto;
    max-width: 1907px;
    position: relative;
    width: 100%;
    &__for-logo{
      min-width: inherit;
      &.visible{
        .slogan{
          animation: centerFromLeft 2s forwards;
        }
        .who-we-are__logo{
          animation: centerFromRight 2s forwards;
        }
      }
    }

    &__for-achievements{
      overflow: hidden;
      padding: 217px 0 155px;

      @media screen and (min-width: 1895px){
        overflow: inherit;
      }
    }
  }

  &__row{
    position: relative;
    height: fit-content;
    &:nth-of-type(2){
      margin-top: 75px;
      height: 92px;
    }
    &:nth-of-type(1){
      height: 157px;
    }
    >*{
      position: absolute;
    }
  }

  &__logo{
    width: fit-content;
    padding: 0 60px;
    right: 0;
  }

  .slogan{
    width: 954px;
    text-align: center;
    font-size: 40px;
    font-weight: 600;
    letter-spacing: 4px;
    text-transform: uppercase;
    left: 60px;
    transform: translateX(0);


    span{
      background: linear-gradient(134deg, #0039CC 0%, #005EFF 101.41%);
      background-clip: text;
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }
  }

  p{
    max-width: 963px;
    width: 100%;
    margin: 0 auto 200px;
    text-align: center;
    font-size: 24px;
    font-weight: 500;
    opacity: 0;
    transition: opacity 1s ease-in-out;
    span{
      font-weight: 600;
      text-transform: uppercase;
    }
    &.visible__p{
      opacity: 1;
    }
  }

  @media screen and (min-width: 685px) and (max-width: 1228px){
    p {
      font-size: calc(24px * 0.8);
      max-width: calc(963px * 0.81);

    }
    .slogan{
      font-size: calc(40px * 0.8);
      max-width: calc(954px * 0.81);
    }
    &__logo{

      svg{
        width: calc(745px * 0.8);
      }
    }
  }
}

.achievement-area{
  position: relative;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  flex-wrap: wrap;
  gap: 60px;
  width: 1898px;
  &__space{
    width: 250px;
    &__mobile{
      display: none;
    }
  }

  @media screen and (min-width: 685px) and (max-width: 1228px){
    transform: scale(.8) translateX(-62%);
    transform-origin: center center;
  }

}


.achievement{
  height: 120px;
  box-shadow: 0 40px 100px 0 rgba(0, 82, 205, 0.50);
  border-radius: 104px;
  &.empty, &.imged{
    width: 518px;
  }
  &.empty{
    background: $primary-gradient-color;
  }
  img{
    height: 100%;
    width: 100%;
  }
  &.texted{
    padding: 40px 60px;
    display: flex;
    align-items: center;
    gap: 11px;
    background: $primary-light-color;
  }

  &__number{
    font-size: 64px;
    font-weight: 700;

    background: linear-gradient(134deg, #0039CC 0%, #005EFF 101.41%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }

  &__string{
    font-size: 20px;
    font-weight: 700;
    min-width: 103px;
    max-width: 177px;
    width: min-content;
    overflow-wrap: break-word;
  }
  &:nth-child(2){
    .achievement__string{
      min-width: 148px;
    }
  }
}

@media screen and (max-width: 685px){
  .who-we-are{
    >img{
      display: none;
    }
    &__logo{
      margin: auto;
      padding: 0;
     svg{
       width: 313px;
       height: 66px;
     }
    }
    &__row:nth-of-type(2){
      margin: 0;
    }
    .slogan{
      font-size: 20px;
      //margin: 70px auto 0;
      width: 312px;
      letter-spacing: 2px;
    }

    &__wrapper{
      max-width: 654px;
      &__for-achievements{
        padding: 50px 0 66px;
      }
    }

    p{
      max-width: 400px;
      margin: 0 auto 51px;
      font-size: 14px;
    }
  }

  .achievement-area{
    gap: 15px;
    width: 654px;
    transform: translateX(-54%);
    &__space{
      width: 164px;
      &__mobile{
        display: block;
      }
    }

  }

  .achievement{
    height: 55px;
    box-shadow: 0 18.333px 45.833px 0 rgba(0, 82, 205, 0.50);
    border-radius: 38.042px;
    &.empty{
      width: 233.1px;
    }
    &.imged{
      width: 237.4px;
    }
    &.texted{
      padding: 18px 32px;
      gap: 5.4px;
    }
    &:nth-child(5){
      order: 1;
    }

    &__number{
      font-size: 29.333px;
    }

    &__string{
      font-size: 10px;
    }
    &:nth-child(2){
      .achievement__string{
        min-width: 73px;
      }
    }
  }
}
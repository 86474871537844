@import "vars";

@keyframes fadeIn {
  0%, 49.98% {
    opacity: 0;
    height: 0;
  }
  49.99%{
    height: auto;
  }
  100% {
    opacity: 1;
    height: auto;
  }
}

@keyframes fadeOut {
  0% {
    opacity: 1;
    height: auto;
  }
  50.01%{
    opacity: 0;
    height: auto;
  }
  50.02%, 100% {
    opacity: 0;
    height: 0;
  }
}

.why-we-are {
  h2 {
    text-align: center;
  }

  padding-bottom: 200px;
}

.argument-list {
  display: grid;
  gap: 20px;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: repeat(2, 286px);
  position: relative;
  margin-top: 50px;

  .circle {
    position: absolute;
    z-index: -1;

    &__orange {
      right: 100px;
      top: 0;
      width: 182px;
      transform: translateY(-50%);
    }

    &__blue {
      bottom: 39px;
      left: 0;
      width: 222px;
      transform: translateX(-50%);
    }
  }
}

.argument {
  text-align: center;
  position: relative;
  border-radius: 20px;
  border: 4px solid #98C1FE;
  background: rgba(255, 255, 255, 0.30);
  backdrop-filter: blur(50px);
  font-size: 36px;
  font-weight: 400;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 18px;
  gap: 14px;
  white-space: pre-line;
  cursor: pointer;
  user-select: none;

  &__count {
    position: absolute;
    font-size: 24px;
    font-weight: 400;
    color: #494ACA;
  }

  p {
    font-size: 20px;
    font-weight: 400;
    animation: fadeOut 0.5s;
    opacity: 0;
    height: 0;

  }

  span {
    animation: fadeIn 0.5s;
    opacity: 1;
    height: auto;
  }

  &:hover {
    p {
      animation: fadeIn 0.5s;
      opacity: 1;
      height: auto;
    }

    span {
      animation: fadeOut 0.5s;
      opacity: 0;
      height: 0;
    }
  }

  &:nth-child(1) {
    .argument__count {
      top: 25px;
      left: 48px;
    }
  }

  &:nth-child(2) {
    .argument__count {
      top: 25px;
      right: 48px;
    }
  }

  &:nth-child(3) {
    .argument__count {
      bottom: 25px;
      left: 48px;
    }
  }

  &:nth-child(4) {
    .argument__count {
      bottom: 25px;
      right: 48px;
    }
  }
}

@media screen and (max-width: 685px) {
  .why-we-are {
    overflow: hidden;
    padding-bottom: 92px;

  }
  .argument-list {
    grid-template-columns: repeat(1, 1fr);
    grid-template-rows: repeat(4, 131px);
    margin-top: 22px;

    .circle {
      &__blue {
        width: 120px;
        top: 77px;
        bottom: auto;
        right: 0;
        left: auto;
        transform: translate(50%, 0);
      }

      &__orange {
        width: 147px;
        top: auto;
        bottom: 80px;
        right: auto;
        left: 0;
        transform: translate(-50%, 0);

      }
    }
  }

  .argument {
    font-size: 18px;

    p {
      font-size: 12px;
    }

    &__count {
      font-size: 12px;
    }

    &:nth-child(1) {
      .argument__count {
        top: 14px;
        left: 16px;
      }
    }

    &:nth-child(2) {
      .argument__count {
        top: 14px;
        right: 16px;
      }
    }

    &:nth-child(3) {
      .argument__count {
        bottom: 14px;
        left: 16px;
      }
    }

    &:nth-child(4) {
      .argument__count {
        bottom: 14px;
        right: 16px;
      }
    }
  }
}